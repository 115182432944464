import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll"
import "./assets/styles/_index.scss"
import GGFXImage from "../../modules/GGFXImage"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const Statistics = (props) => {
  return (
    <div className="statistics-bk">
      <Container>
        <Row>
          <Col md={12}>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={100}
              offset={100}
            >
              {props?.title && <h2>{props?.title}</h2>}
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={200}
              offset={100}
            >
              {props?.description && (
                <div className="description-bk"><ContentModule Content={props.description?.data?.description} /></div>
              )}
            </ScrollAnimation>

            <div className="d-grid statistics-list">
              {props?.item?.map((node, i) => {
                return (
                  <div
                    // animateInType={"up"}
                    // delay={i * 200}
                    className="statistics-item"
                  >
                    <div className="text-bk">
                      <div className="count">{node.value}</div>
                      <p className="name">{node?.title}</p>
                      <div className="comments">
                        <ContentModule
                          Content={node.description?.data?.description}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Statistics
