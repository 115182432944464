import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import loadable from "@loadable/component"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import TeamsCard from "../TeamsCard/TeamsCard"
import "./assets/styles/_index.scss"
import ScrollAnimation from "react-animate-on-scroll"
import { Link } from "gatsby"
import { PageLinks } from "../../common/site/page-static-links"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const Slider = loadable(() => import("react-slick"))

const TeamSlider = (props) => {
  // Slider settings
  const settings = {
    dots: props?.data?.length > 3,
    arrows: false,
    infinite: false,
    speed: 800,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: props?.data?.length > 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          speed: 400,
          arrows: false,
          dots: true,
        },
      },
    ],
  }
  // Slider settings

  return (
    <section className="team-slider-wrapper">
      <Container>
        <Row>
          <Col md={8}>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={100}
              offset={100}
            >
              {props?.title && <h2>{props?.title}</h2>}
            </ScrollAnimation>
          </Col>
          <Col md={4}>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={200}
              offset={100}
            >
              <Link
                to={"/"+PageLinks.team}
                className="button button-secondary-outline d-none d-md-flex"
              >
                More of our team
              </Link>
            </ScrollAnimation>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Slider className="team-slider" {...settings}>
              {props?.teams?.length > 0 &&
                props.teams?.map((module, index) => {
                  return (
                    <div className="team-slide">
                      <TeamsCard isslide data={module} id={props.id} />
                    </div>
                  )
                })}
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default TeamSlider
