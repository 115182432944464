import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll"
import "./assets/styles/_index.scss"
import GGFXImage from "../../modules/GGFXImage"
import CTALink from "../../modules/cta_link"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const IconBlock = (props) => {
  return (
    <div className={`${props?.style} ${props?.title ? "icon-bk with-title" : "icon-bk"}`}>
      <Container>
        <Row>
          <Col md={12}>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={100}
              offset={100}
            >
              {props?.title && <h2>{props?.title}</h2>}
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={200}
              offset={100}
            >
              {props?.description && (
                <div className="description-bk"><ContentModule Content={props.description?.data?.description} /></div>
              )}
            </ScrollAnimation>

            <div className="d-grid icon-list">
              {props?.item?.map((node, i) => {
                return (
                  node?.cta ?
                  <CTALink
                  class=""
                  {...node?.cta}
                  // title={item?.cta?.label}
                  target_window={node?.cta?.is_external? "is_external" : ""}
                >
                  <div
                    // animateInType={"up"}
                    // delay={i * 200}
                    className="icon-item"
                  >
                    <div className="img-bk">
                      {/* <ImageModule
                      ImageSrc={node?.image?.url}
                      altText={node?.image?.alternativeText}
                      imagetransforms={processedImages}
                      renderer="srcSet"
                      imagename={imagename}
                      strapi_id={node?.strapi_id}
                      classNames="img-card"
                    /> */}
                      <GGFXImage
                        ImageSrc={node?.icon}
                        key=""
                        // altText={altText}
                        imagetransforms={props?.ggfx_results}
                        renderer="srcSet"
                        imagename={props?.style === "style2" ? "page.Icon_slider_item_icon.smallicon" : "page.Icon_slider_item_icon.icon"}
                        strapiID={props?.strapiID}
                        className=""
                      />
                    </div>
                    <div className="text-bk">
                      <p className="name">{node?.title}</p>
                      {node.description?.data?.description &&<div className="comments">
                        <ContentModule
                          Content={node.description?.data?.description}
                        />
                      </div>}
                    </div>
                  </div>
                  </CTALink>
:
<div
// animateInType={"up"}
// delay={i * 200}
className="icon-item"
>
<div className="img-bk">
  {/* <ImageModule
  ImageSrc={node?.image?.url}
  altText={node?.image?.alternativeText}
  imagetransforms={processedImages}
  renderer="srcSet"
  imagename={imagename}
  strapi_id={node?.strapi_id}
  classNames="img-card"
/> */}
  <GGFXImage
    ImageSrc={node?.icon}
    key=""
    // altText={altText}
    imagetransforms={props?.ggfx_results}
    renderer="srcSet"
    imagename={props?.style === "style2" ? "page.Icon_slider_item_icon.smallicon" : "page.Icon_slider_item_icon.icon"}
    strapiID={props?.strapiID}
    className=""
  />
</div>
<div className="text-bk">
  <p className="name">{node?.title}</p>
  {node.description?.data?.description &&<div className="comments">
    <ContentModule
      Content={node.description?.data?.description}
    />
  </div>}
</div>
</div>
                )
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default IconBlock
