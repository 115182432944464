import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import loadable from "@loadable/component"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./assets/styles/_index.scss"
import ScrollAnimation from "react-animate-on-scroll"
import { Link } from "gatsby"
import { PageLinks } from "../../common/site/page-static-links"
import NewsCard from "../NewsCard/NewsCard"
import { useStaticQuery, graphql } from "gatsby"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const Slider = loadable(() => import("react-slick"))

const GuideSlider = (props) => {
  // Slider settings
  const settings = {
    dots: props?.data?.length > 3,
    arrows: false,
    infinite: false,
    speed: 800,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: props?.data?.length > 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          speed: 400,
          arrows: false,
          dots: true,
        },
      },
    ],
  }
  // Slider settings
  const data = useStaticQuery(graphql`
  query {
    allStrapiBlog(filter: {category: {strapi_json_value: {eq: "Guides"}}}) {
      nodes {
        title
          slug
          strapi_id
          tile_image {
            url
          }
          ggfx_results {
            src_cftle
            transforms {
              url
              transform
              format
            }
          }
      }
    }
  }
`);
const guidesData = data?.allStrapiBlog?.nodes
  return (
    <section className="guide-slider-wrapper">
      <Container>
        <Row>
          <Col md={8}>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={100}
              offset={100}
            >
              {props?.title && <h2>{props?.title}</h2>}
            </ScrollAnimation>
          </Col>
          <Col md={4}>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={200}
              offset={100}
            >
              <Link
                to={"/"+PageLinks.news}
                className="button button-secondary-outline d-none d-md-flex"
              >
                More Guides
              </Link>
            </ScrollAnimation>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Slider className="guide-slider" {...settings}>
              {guidesData?.length > 0 &&
                guidesData?.map((module, index) => {
                  return (
                    <div className="guide-slide">
                      <NewsCard isslide data={module}  />
                    </div>
                  )
                })}
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default GuideSlider
