import React from "react"
import { Link, navigate } from "gatsby"
import { PageLinks } from "../../common/site/page-static-links"

import "./assets/styles/_index.scss"
import GGFXImage from "../../modules/GGFXImage"

const TeamsCard = (props) => {
  const ImageRenderList = ({ item, imagename }) => {
    return (
      <GGFXImage
        ImageSrc={item?.image}
        key=""
        // altText={altText}
        altText={`${item.title} ${
          item.designation ? ` | ${item.designation}` : ""
        }`}
        imagetransforms={item?.ggfx_results}
        renderer="srcSet"
        imagename="team.image.tile_image"
        strapiID={item.strapi_id}
        className=""
      />
      //   <ImageModule
      //     ImageSrc={item.image}
      //     title={item.title}
      //     altText={`${item.title} ${
      //       item.designation ? ` | ${item.designation}` : ""
      //     }`}
      //     imagetransforms={processedImages}
      //     renderer="srcSet"
      //     imagename={imagename}
      //     strapi_id={item.strapi_id}
      //   />
    )
  }
  return (
    <div className="news-card-wrapper teams-wrapper">
      <div className="news-card-img-zoom teams-img">
        {props?.data?.content?.data?.content ?
        <Link to={`/${PageLinks.team}/${props.data.slug}/`}>
          <ImageRenderList
            item={props?.data}
            imagename="team.image.tile_image"
          />
        </Link>:
                <ImageRenderList
                  item={props?.data}
                  imagename="team.image.tile_image"
                />
}
      </div>
      <div className="news-card-text-wrapper">
        <p className="news-card-title teams-title">
        {props?.data?.content?.data?.content ?
          <Link to={`/${PageLinks.team}/${props.data.slug}/`}>
            {props.data.name}
          </Link>:
                      <>{props.data.name}</>}
        </p>
        {props.data.designation && (
          <div className="teams-card-position">{props.data.designation}</div>
        )}
        {/* {!props?.isslide &&
        <div className="teams-card-mobile">
          {props.data.phone && (
            <a href={`tel:${props.data.phone}`}>{props.data.phone}</a>
          )}{" "}
          {props.data.email ? "/" : ""}{" "}
          {props.data.email && (
            <a
              className="link-underline"
              onClick={() =>
                navigate("/about/our-team/contact/", {
                  state: { name: props.data.name, email: props.data.email },
                })
              }
            >
              Email
            </a>
          )}
        </div>} */}

        {/* {props.data.email && (
          <div className="teams-card-mail">
            <a
              className="link-underline"
              onClick={() =>
                navigate("/about/our-team/contact/", {
                  state: { name: props.data.title, email: props.data.email },
                })
              }
            >
              {props.data.email}
            </a>
          </div>
        )} */}
      </div>
    </div>
  )
}

export default TeamsCard
